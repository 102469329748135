/* below is leadership css */
.leadership {
    text-align: left;
    margin-bottom: 10svh;
} 
  
.leadership #intro-photo {        
    max-height: 35svh;
    border-radius: 50pt;        
}
  
.leadership #intro-title {
    font-weight: bolder;
    text-align: left;
    margin-bottom: 4%;    
}
    
#contact-yiqi {
    flex: 1; 
    border: solid; 
    border-radius: 15pt;
    background-color: beige;
    margin-top: 6%;
    margin-bottom: 2%;
    margin-left: 30%;
    margin-right: 30%;
    font-weight: bolder;
    text-align: center;
    align-items: left;
}


    
  /* optimize webpage for mobile */
@media (max-width: 768px) {
    .leadership #intro-photo {
        max-height: 25svh;
        border-radius: 30pt;
        margin-bottom: 3svh;
    }

    .leadership #intro-title {
        text-align: center;
    }

}

