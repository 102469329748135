.login-container {
    text-align: center;
    min-height: 60svh;
}

.login-container .row {
    max-width: 300px;
}

.login-container .title {
    font-size: 30px;
    margin: auto;
    margin-bottom: 5vh;
}

.login-container .login-button {
    max-width: 200px;
    border-radius: 10px;
}


#error-message {
    color: red;
    font-size: 0.8rem;
    margin: 0.5rem 0;
    text-align: center;
}

