* {
  margin: auto;
  font-family: Arial;    
  color: black; 
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  /* button attributes */
  --button-color: #00ED64;
  --button-color-transparent: rgba(0, 237, 100, 0.3);  
  --button-color-hover:  color(#00ED64);
  --button-text-color: white;
  --button-border-radius: 5px;
  --button-border-radius-hover: 25px;
  --button-border-width: 1px;
  --button-border: solid gray; 

  /* page width */
  --max-width: 1024px;

  /* dark color */
  --dark-color:  #061621; 

  /* page height */
  --min-height-page: 80svh;

  /* font sizes */
  --font-size-title: 30px; 
  --font-size-small-title: 15px;
  --font-size-text: 20px;

  /* font weight */
  --font-weight-title: 600;
  --font-weight-small-title: 500;
}

#small-title {
  font-size: 10pt;  
  margin: 1svh auto;
  text-align: center;
}

/* DEV MODE: highlights all borders */

/* *, .header, .menu-section, .contact-section, .logo-section, .home, .segment, .row, .column, .column-2 .overlay {
  border: solid black;
  border-width: 0.05pt;
}

.slider, .slider-logo, #slider-logo-id {
  border: solid black;
}

.col-key, .col-val {
  border: solid black;
} */