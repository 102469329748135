.property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding-bottom: 10%;
}

.property-card {
    border: 1px solid #ccc;
    padding: 16px;
    text-align: center;
}
  
.property-card img {
    max-width: 100%;
    height: auto;
    margin-top: 8px;
}