.marketplace {
    /* display: flex; */
}

.marketplace .row {
    margin-bottom: 6svh;
    flex: 1;
}

.marketplace .headline-title, .marketplace #highlight {
    font-size: 40pt; 
}

.marketplace #highlight {    
    color: purple;
    font-weight: 900;
}

.marketplace #text {
    font-size: 18pt;
    /* border: solid rgb(1, 1, 1,0.1); */
    /* border-radius: 10px; */
    margin: auto;
    /* padding: 10%; */
}

.marketplace .highlight-transparent{
    font-size: 1em;    
    background-color: rgba(0, 0, 0, 0.0); 
    text-shadow: 0px 0px 10px rgba(0,0,0, 0.9); 
    border-radius: 5px;     
    backdrop-filter: blur(5px); 
}

.marketplace #text {
    margin: auto;
}

.marketplace #img-order-book {
    max-width: 20svw;
    border-radius: 20px;
}

.marketplace #img-map-stats {
    max-width: 50svw;
    border-radius: 20px;
}

/* below is for the slider */
.slider {    
    height: 40svh;     
    width: 100%;
    margin: auto;        
    margin-bottom: 5svh;
    text-align: center;    
}

.slider-logo {  
    margin: auto;    
    border: solid rgb(1, 1, 1,0.2);
    border-radius: 5px;
}

#slider-logo-id {        
    height: 30svh;     
    max-width: 60%;       
}

.slider-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Maintain aspect ratio without stretching */
  }
  

/* Blur effect for non-centered slides */
.slick-slide {
    transition: filter 1s ease; /* Smooth transition for the blur effect */
}

.slick-center {
    filter: none; /* Remove blur effect for centered slide */        
}

.slick-slide:not(.slick-center) {
    filter: blur(5px); /* Apply blur effect to non-centered slides */    
}

@media (max-width: 768px) {
    /* #slider-logo-id {
        max-width: 100%;
    } */
    .marketplace .headline-title, .marketplace #highlight {
        font-size: 20pt; 
    }
    .marketplace #img-order-book {
        max-width: 50svw;
        margin-bottom: 6svh;
    }
    .marketplace #text {
        font-size: 15pt;
    }
}
