#title {
  text-align: center;
  font-size: 50pt;   
  font-weight: bolder;
  font-family: arial;
  margin-bottom: 1%;
}

#small-text {
  text-align: center;
  font-size: 10pt;
  margin-bottom: 3%;
}

#text {
  font-size: 14pt;
  text-align: left;
  margin-bottom: 2svh
}

.row {
  display: flex;  
  margin: auto;  
  max-width: 1000px;
  /* border: solid lightgreen; */
}

.column {
  flex: 1;         
  border-radius: 0;
  margin: auto;
  margin-bottom: 2svh;
  text-align: center;    /* text-align works on aligning the image to the center of the column*/
  /* border: solid black; */
}

 /* optimize webpage for mobile */
 @media (max-width: 768px) {
  #title {
    font-size: 35pt;
  }
  #small-text {
    font-size: 8pt;
  }
  #text {
    font-size: 14pt;
  }
  .row  {
    flex-direction: column;      
  }        
}

