.title-report {
    text-align: center;
    /* border: solid black; */
    /* border-radius: 10px; */
    max-width: 1000px;
    padding: 20px;        
    margin: auto; 
    min-height: var(--min-height-page);
}

.title-report #title{
    font-size: 30px;
}

.title-report #small-title{
    font-size: 15px;
    font-weight: bolder;    
}

.title-report .row {
    max-width: var(--max-width);
}

.property-info, .legal-description, .schedule-a, .schedule-b {
    text-align: left;
}

/* PROPERTY INFORMATION */
.col-key {
    flex: 1;
}

.col-val{
    flex: 4;
    font-family: monospace;
}
