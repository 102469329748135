.database {
    max-width: var(--max-width); 
    min-height: var(--min-height-page);
    margin: auto;    
}

.database .row {
    display: flex;
    margin: auto;
    margin-bottom: 6svh;
}


/* v1 css */
.database .tab-panel {
    /* border: solid black; */
    min-height: 80svh;
}

.tab-panel #headline {
    font-size: 25px;
    text-align: center;
}

#search-item {   
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 7px;
    padding-right: 5px;
    text-align: center;
    max-width: 70svw;
}

#pricing {
    min-height: 50svh; 
    min-width: 80%;        
    margin-bottom: 50svh;    
}

#pricing-column {
    min-height: 40svh;
    background-color: rgb(0,0,0,0.05);
    border-radius: 10px;
    margin: 10px;    
    padding: 15px;
    max-width: 400px; 
}

#pricing-heading {
    text-align: center;
    font-size: 20px;
}

#pricing-texts {
    min-height: 90%;
}

#pricing-term {    
    text-align: left;
    padding: 5px 0 5px 0;
    font-size: 15px;
}

/* v0 css */

.database .row .column .row {
    margin-bottom: 0;
}

.database .small-title {
    text-align: center;
    font-size: var(--font-size-small-title);
    flex: 1;
    margin-top: 2svh;
}

.database .headline-title {
    flex: 1;
    text-align: center;
    font-size: 30pt; 
    font-family: monospace;
    margin: auto; 
    margin-left: 10px; 
    margin-right: 10px; 
}

.database .headline-title #highlight {
    color: #023d29;
    font-size: 40pt;
    font-family: monospace;
    font-weight: 900;
}


.row .column {    
    flex: 1; 
    flex-direction: row;
    font-size: 15pt;
    margin: auto;
}

.row .column #pic, #pic-mobile {
    place-items: center;    
    max-width: 100%;
    max-height: auto;
}

#pic-mobile {
    padding: 5%;
}

.row .column #title {
    font-size: 28pt;
}

/* modify the button color */
#button-database {
    /* flex: 1;  */
    background-color: var(--button-color);
    color: black; 
    border: solid;
    border-radius: var(--button-border-radius);
}

/* modify the button hover over color */
#button-database:hover {
    border-radius: var(--button-border-radius-hover);
}

#statement-animation {
    border-radius: 15px;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: rgba(128, 128, 128, 0.08);
    text-align: center;
    font-weight: bolder;
  }

/* optimize webpage for mobile */
@media (max-width: 768px) {
    #pic {
        display: none; 
    }    
    .row {
        flex-direction: column;   
    }        
    .row .column .row {
        flex-direction: row;
    }
    .database .headline-title, .database .headline-title #highlight {
        font-size: 20pt;
    }
    .row .column {
        font-size: 12pt; 
        margin-bottom: 15px;
    }
    .row .column #title {
        font-size: 18pt;
    }
    #button-database {
        font-size: 13.5pt;
    }
    #pricing-column {
        margin: auto;
        margin-bottom: 20px;        
    }
}

/* optimize webpage for desktop  */
@media (min-width: 769px) {
    #pic-mobile {
        display: none;         
    }
}